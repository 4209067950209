import React from 'react';
import styles from './settings.module.css';
import { ItemBlock, Toggle, Header, Button } from '../../components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const Settings = () => {
	const navigate = useNavigate();
	const { usePin } = useSelector((state) => state.storage);
	const [fingerChecked, setFingerChecked] = React.useState(false);
	const [faceChecked, setFaceChecked] = React.useState(false);

	const list = [
		{
			title: 'Use PIN Code',
			icon: 'lock',
			checked: usePin,
		},
		{
			title: 'Use Face ID',
			icon: 'face',
			checked: faceChecked,
		},
	];

	const handleToggle = (_, title) => {
		switch (title) {
			case 'Use PIN Code':
				if (usePin) {
					navigate('/login-pin', { state: { to: '/settings' } });
				} else {
					navigate('/create-pin', { state: { to: '/settings' } });
				}
				break;
			case 'Touch Id':
				setFingerChecked(!fingerChecked);
				break;
			case 'Use Face ID':
				setFaceChecked(!faceChecked);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<div className={styles.list} style={{ marginTop: 30 }}>
				{usePin && (
					<div className='page-inner' style={{ marginBottom: 50 }}>
						<p className={styles.passwordChangePar}>
							Authentification & PIN code
						</p>
						<Button to='/create-pin' state={{ to: '/settings' }}>
							Change my PIN code
						</Button>
					</div>
				)}
				{list.map((item, index) => (
					<ItemBlock content={item} key={index}>
						<Toggle
							data={usePin}
							checked={item.checked}
							id={`safety` + index}
							dataFunc={item.title}
							onToggle={handleToggle}
						/>
					</ItemBlock>
				))}
			</div>
		</>
	);
};
