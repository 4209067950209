import React from 'react';
import styles from './dApps.module.css';
import { Button, Header } from '../../components';

export const DAppsStart = () => {
	return (
		<div>
			<div
				className='page-inner'
				style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
			>
				<div className={styles.logo_block} style={{ flexGrow: 1 }}>
					<div className={styles.title}>No dApp connected yet.</div>
				</div>
			</div>
			<div style={{ marginTop: 80 }}>
				<h5 className={styles.subTitle}>Tap to connect a dApp.</h5>
				<p className={styles.par}>
					Your Martian Wallet is compatible <br /> with WalletConnect.
				</p>
				<Button style={{ marginTop: 40 }} variant='default' to='/dapps-connect'>
					Connect
				</Button>
			</div>
		</div>
	);
};
