import React from 'react';
import styles from './home.module.css';
import {
	Menu,
	Indicators,
	Transaction,
	ButtonActions,
	Button,
} from '../../components';
import Svg from '../../svgs/Svg';

import coin1 from '../../assets/images/coin-1.png';
import classNames from 'classnames/bind';
import { Area, AreaChart } from 'recharts';
import { useLocation } from 'react-router-dom';
import fixNum from '../Func.wallet/fixNum';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchChartCoin,
	fetchChartCoinDefault,
} from '../../redux/slices/WalletSlice';
import { ChartLoader } from './../../components/Loaders/ChartLoader';

export const AssetInfo = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [activeTimeline, setActiveTimeline] = React.useState('All');
	const { chartBitcoin, statusChartBitcoin } = useSelector(
		(state) => state.wallet
	);

	const buttonActions = [
		{
			title: '1D',
		},
		{
			title: '7D',
		},
		{
			title: '1M',
		},
		{
			title: '1Y',
		},
		{
			title: 'All',
		},
	];

	React.useEffect(() => {
		if (state !== null && state.contract_address !== '') {
			dispatch(fetchChartCoin([state.contract_address, setPeriod()]));
		} else {
			dispatch(fetchChartCoinDefault([state.id, setPeriod(true)]));
		}
	}, [activeTimeline]);

	const setPeriod = (eth) => {
		const day = eth ? '1' : 'day';
		const week = eth ? '7' : 'week';
		const month = eth ? '30' : 'month';
		const year = eth ? '360' : 'day';
		const all = eth ? 'max' : 'max';
		switch (activeTimeline) {
			case '1D':
				return day;
			case '7D':
				return week;
			case '1M':
				return month;
			case '1Y':
				return year;
			case 'All':
				return all;
			default:
				break;
		}
	};

	return (
		<div className='page'>
			{/* Меню наверху, чтобы влиять на стили page-inner */}
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className='page-header'>
					<div className={styles.goBackBtn} onClick={() => navigate(-1)}>
						<svg
							width='9'
							height='14'
							viewBox='0 0 9 14'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path d='M8 1L2 7L8 13' stroke='black' strokeWidth='2' />
						</svg>
						<span>Back</span>
					</div>
				</div>
				<div className={styles.assetItem}>
					<div className={styles.title}>
						<div className={styles.title_logo}>
							<img src={state !== null ? state.image.thumb : ''} />
						</div>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{state !== null ? state.name : ''}
							<p>
								{state !== null
									? fixNum(state.market_data.balance) +
									  ' ' +
									  state.symbol.toUpperCase()
									: ''}
							</p>
						</div>
					</div>
					<div
						className={styles.link}
						onClick={() => navigate('/buy', { state: { item: state } })}
					>
						Buy
						<Svg type='arrow-right' fill='var(--white)' />
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						width: '100%',
						marginBottom: 10,
						marginTop: 34,
					}}
				>
					<div className={styles.info}>
						<div className={styles.heading}>PRICE</div>
						<div className={classNames('title', styles.title_info)}>
							${state !== null ? fixNum(state.market_data.current_price) : ''}
							<span
								className={classNames(styles.indicators, {
									[styles.green]: state.changes.percent > 0,
								})}
							>
								{state.changes.percent > 0 ? '+' : ''}
								{fixNum(state.changes.percent)}%
							</span>
						</div>
					</div>
				</div>
				{chartBitcoin !== null ? (
					<div className={styles.wrapBlock}>
						<div className={styles.chart}>
							<AreaChart
								width={window.innerWidth - 40}
								height={130}
								data={chartBitcoin}
								margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
							>
								<defs>
									<linearGradient id='value' x1='0' y1='0' x2='0' y2='1'>
										<stop
											offset='0%'
											stopColor='var(--black)'
											stopOpacity={0.3}
										/>
										<stop
											offset='30%'
											stopColor='var(--black)'
											stopOpacity={0.1}
										/>
										<stop
											offset='95%'
											stopColor='var(--black)'
											stopOpacity={0}
										/>
									</linearGradient>
								</defs>
								<Area
									type='monotone'
									dataKey='value'
									stroke='var(--black)'
									fillOpacity={1}
									fill='url(#value)'
								/>
							</AreaChart>
						</div>
						<ButtonActions
							disabled={statusChartBitcoin !== 'success'}
							activeItem={4}
							type='timeline'
							onClick={setActiveTimeline}
							actions={buttonActions}
						/>
					</div>
				) : (
					<ChartLoader width={window.innerWidth - 40} />
				)}
				<div className='btns' style={{ marginTop: 10 }}>
					<Button
						onClick={() =>
							navigate('/send-amount', {
								state: { item: state, from: 'swapOne' },
							})
						}
					>
						Send
					</Button>
					<Button
						variant='outlined'
						onClick={() => navigate('/receive', { state: { item: state } })}
					>
						Receive
					</Button>
				</div>
				<div className={styles.list} style={{ marginTop: 30 }}>
					<div
						className='list-title'
						style={{
							fontFamily: 'var(--magneta)',
							color: 'var(--black)',
							fontSize: 16,
						}}
					>
						Balance
					</div>

					{state !== null ? <Transaction info data={state} /> : <></>}
				</div>
			</div>
		</div>
	);
};
