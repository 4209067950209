import React from 'react';
import styles from './manage.module.css';
import { Button, Popup, Toggle } from '../../components';

import logo1 from '../../assets/images/coin-1.png';
import logo2 from '../../assets/images/coin-2.png';
import logo3 from '../../assets/images/coin-3.png';
import logo4 from '../../assets/images/coin-4.png';
import logo5 from '../../assets/images/coin-5.png';
import { useSelector, useDispatch } from 'react-redux';
import { setChooseAssets } from '../../redux/slices/StorageSlice';

export const Manage = () => {
	const dispatch = useDispatch();
	const { chooseAssets } = useSelector((state) => state.storage);
	const [showSuccess, setShowSuccess] = React.useState(false);
	const { walletAddress } = useSelector((state) => state.wallet);

	const list = [
		{
			logo: logo1,
			title: 'Bitcoin',
			amount: '0 BTC',
		},
		{
			logo: logo2,
			title: 'Ethereum',
			amount: '0 ETH',
		},
		{
			logo: logo3,
			title: 'BNB',
			amount: '0 BNB',
		},
		{
			logo: logo4,
			title: 'Polygon',
			amount: '0 Matic',
		},
		{
			logo: logo5,
			title: 'Avalanche',
			amount: '0 Avax',
		},
	];

	const handleToggle = (data) => {
		dispatch(setChooseAssets(data.amount.split(' ')[1].toLowerCase()));
	};

	return (
		<div className='page'>
			<div className='page-inner'>
				<div className='title'>Manage cryptos</div>
				<div className='subtitle'>
					Choose what tokens will be displayed in your wallet by default. You
					can always change this later!
				</div>
				<div className={styles.list}>
					{list.map((item, index) => (
						<div className={styles.item} key={index}>
							<div className={styles.item_img}>
								<img src={item.logo} alt={item.title} />
							</div>
							<div className={styles.item_content}>
								<div className={styles.item_title}>{item.title}</div>
								<span className={styles.item_subtitle}>{item.amount}</span>
							</div>
							<Toggle
								data={item}
								checked={chooseAssets.includes(
									item.amount.split(' ')[1].toLowerCase()
								)}
								id={`toggle` + index}
								onToggle={handleToggle}
							/>
						</div>
					))}
					<p className={styles.parFooter}>You can always change this later!</p>
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<Button variant='default' onClick={() => setShowSuccess(true)}>
					Go to my wallet
				</Button>
			</div>
			<Popup
				setIsOpen={setShowSuccess}
				isOpen={showSuccess}
				title='Congratulations!'
			>
				<div>
					<p className={styles.popupTitle}>
						You have successfully added a new wallet
					</p>
					{walletAddress !== '' && (
						<p className={styles.popupSubTitle}>
							{walletAddress}
							<br />
							(Wallet Address)
						</p>
					)}
				</div>
				<Button style={{ width: '55%', marginTop: 23 }} to='/create-pin'>
					Finish!
				</Button>
			</Popup>
		</div>
	);
};
