import React from 'react';
import Svg from '../../svgs/Svg';
import styles from './copyButton.module.css';

export const CopyButton = ({ text, style, phrase }) => {
	const [copied, setCopied] = React.useState(false);

	const handleCopyToClipboard = async (e) => {
		e.preventDefault();
		try {
			await navigator.clipboard.writeText(phrase);
			setCopied(true);
			const timerID = setTimeout(() => {
				setCopied(false);
				clearTimeout(timerID);
			}, 1200);
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err);
		}
	};

	return (
		<a
			href='#'
			onClick={(e) => handleCopyToClipboard(e)}
			className={styles.styleBtn}
			style={style}
		>
			{!copied ? text : 'It was copied!'}
			<Svg
				width={18}
				style={{ marginLeft: 10 }}
				type='arrow-right'
				fill='white'
			/>
		</a>
	);
};
