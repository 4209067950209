import React from 'react';
import styles from './import.module.css';
import { Button, Menu } from '../../components';

import welcome from '../../assets/images/welcome.png';

export const ImportBackup = () => {
	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className={styles.logo}>
					<div className={styles.title}>Manual backup</div>
					<img src={welcome} alt='Martian Wallet' />
				</div>
			</div>

			<div className='page-inner page-align-bottom'>
				<Button variant='default'>Perform manual backup</Button>
				<Button variant='outlined'>Add Node</Button>
			</div>
		</div>
	);
};
