import React from 'react';
import styles from './generate.module.css';
import { Button, Digits, Header } from '../../components';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

export const CreatePin = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { passwordInit } = useSelector((state) => state.wallet);
	const [disabledBtn, setDisabledBtn] = React.useState(true);

	React.useEffect(() => {
		// alert(passwordInit.length)
		if (passwordInit.length === 6) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [passwordInit]);

	const goConfirm = () => {
		if (state !== null && state.to == '/settings') {
			navigate('/confirm-pin', { state: { to: state.to } });
		} else {
			navigate('/confirm-pin');
		}
	};

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header title='Create a PIN code' />
				<Digits name='create' />
				<div className={styles.subtitle}>
					Create a PIN code to secure your wallet.
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<Button
					variant={disabledBtn ? 'disabled' : 'default'}
					onClick={goConfirm}
				>
					Continue
				</Button>
				<Button variant='inline' to='/home'>
					Continue without PIN code
				</Button>
			</div>
		</div>
	);
};
