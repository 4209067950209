import React from 'react';
import styles from './ButtonLink.module.css';
import { Box } from '../UI/Box/Box';
import classNames from 'classnames/bind';

export const ButtonLink = ({
	disabled,
	title,
	subtitle,
	onClick,
	imgSource,
}) => {
	return (
		<Box
			onClick={onClick}
			className={classNames(styles.box, disabled ? styles.disabled : '')}
		>
			<img src={imgSource} alt='img' />
			<div>
				<div className={styles.heading}>
					<span className={styles.title}>{title}</span>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M9 5L15 12L9 19'
							stroke={disabled ? 'rgba(0,0,0,0.2)' : 'black'}
							strokeWidth='2'
						/>
					</svg>
				</div>
				{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
			</div>
		</Box>
	);
};
