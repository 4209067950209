import React from 'react';
import styles from './settings.module.css';
import { Header, ItemBlock } from '../../components';
import jsonVersion from '../../../package.json';

export const SettingsAbout = () => {
	const list = [
		{
			title: 'Terms of Use',
			link: 'https://www.ordinals-wallet.app/terms.html',
		},
		{
			title: 'Privacy policy',
			link: 'https://www.ordinals-wallet.app/policy.html',
		},
	];

	return (
		<>
			<h6 className={styles.versionPar}>v. {jsonVersion.version}</h6>
			<div className={styles.list}>
				{list.map((item, index) => (
					<ItemBlock
						onClick={() => window.open(item.link)}
						content={item}
						key={index}
					/>
				))}
			</div>
		</>
	);
};
