import React from 'react';
import ContentLoader from 'react-content-loader';

export const ChartLoader = ({ style, px = 40 }) => {
	return (
		<ContentLoader
			style={style}
			speed={2}
			width={window.innerWidth - px}
			height={208}
			viewBox={`0 0 ${window.innerWidth - px} 208`}
			backgroundColor='#AFAFAF'
			foregroundColor='#F8F8FA'
		>
			<rect
				x='0'
				y='0'
				rx='6'
				ry='6'
				width={window.innerWidth - px}
				height='208'
			/>
		</ContentLoader>
	);
};
