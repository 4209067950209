import React from 'react';
import { Button, InputCopy, Menu, Header } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { setAddressTo } from '../../redux/slices/TransactionsSlice';
import Svg from '../../svgs/Svg';
import styles from './send.module.css';

export const SendAddress = () => {
	const dispatch = useDispatch();
	const { addressTo } = useSelector((state) => state.transaction);
	const [disabledBtn, setDisabledBtn] = React.useState(true);

	React.useEffect(() => {
		if (
			(addressTo !== '' && addressTo.length == 42) ||
			addressTo.length == 34 ||
			addressTo.length == 62
		) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [addressTo]);

	const setValueAddress = (value) => {
		dispatch(setAddressTo(value));
	};

	async function pasteWords(e) {
		e.preventDefault();
		navigator.clipboard
			.readText()
			.then((text) => {
				dispatch(setAddressTo(text));
			})
			.catch((err) => {
				console.error('Failed to read clipboard contents: ', err);
			});
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Send to address' />
				<div className={styles.wrapAddress}>
					<input
						placeholder='Paste address'
						className={styles.inputAddress}
						type='text'
						value={addressTo}
						onInput={(e) => setValueAddress(e.target.value)}
					/>
					<a
						href='#'
						className={styles.copyButton}
						onClick={(e) => pasteWords(e)}
					>
						<span>
							<Svg
								width={20}
								height={20}
								type='copy-inlined'
								fill='var(--black)'
							/>
						</span>
						Paste from clipboard
					</a>
				</div>
			</div>

			<div className='page-inner page-align-bottom'>
				{addressTo !== '' && (
					<p className={styles.parGray}>Check the address you have copied</p>
				)}
				<Button
					variant={disabledBtn ? 'disabled' : 'default'}
					to='/send-preview'
				>
					Preview Transaction
				</Button>
			</div>
		</div>
	);
};
