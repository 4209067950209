import React from 'react';
import styles from './send.module.css';
import { Button, Menu } from '../../components';
import {
	setChooseCoinOne,
	setChooseCoinTwo,
	setAmountSend,
} from '../../redux/slices/TransactionsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import fixNum from '../Func.wallet/fixNum';
import Svg from '../../svgs/Svg';
import { ItemLoader } from '../../components/Loaders/ItemLoader';

export const SendAmount = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { chooseCoinOne, amountSend } = useSelector(
		(state) => state.transaction
	);
	const [disabledBtn, setDisabledBtn] = React.useState(true);
	const [amountBuy, setAmountBuy] = React.useState('');

	React.useEffect(() => {
		console.log(amountSend);
		if (amountSend !== '' && amountSend !== 0) {
			setAmountBuy(amountSend);
		}
	}, []);

	React.useEffect(() => {
		if (
			amountBuy !== '' &&
			amountBuy > 0 &&
			chooseCoinOne.market_data.balance >= amountBuy
		) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [amountBuy]);

	React.useEffect(() => {
		if (state !== null) {
			if (state.from == 'swapOne') {
				dispatch(setChooseCoinOne(state.item));
			}
			if (state.from == 'swapTwo') {
				dispatch(setChooseCoinTwo(state.item));
			}
		}
	}, [state]);

	const setValue = () => {
		dispatch(setAmountSend(amountBuy));
		navigate('/send-address');
	};

	const onMax = () => {
		setAmountBuy(chooseCoinOne.market_data.balance);
	};

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className='page-header'>
					<div className={styles.goBack} onClick={() => navigate(-1)}>
						<svg
							width='9'
							height='14'
							viewBox='0 0 9 14'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path d='M8 1L2 7L8 13' stroke='black' stroke-width='2' />
						</svg>
						Back
					</div>
					<div>
						<button onClick={onMax} className={styles.maxBtn}>
							MAX
						</button>
					</div>
				</div>

				{chooseCoinOne !== null ? (
					<div className={styles.wrapCoin}>
						<div className={styles.logo}>
							<Svg type='logo-icon' fill='var(--white)' />
						</div>
						<div style={{ marginTop: -10, maxWidth: '85%' }}>
							<span className={styles.coinPrice}>
								<input
									placeholder='0'
									value={amountBuy}
									onInput={(e) => setAmountBuy(e.target.value)}
								/>
							</span>
							<h5 className={styles.coinTitle}>
								≈$
								<span>
									{+amountBuy > 0
										? fixNum(
												chooseCoinOne.market_data.current_price * +amountBuy
										  )
										: 0}
								</span>{' '}
							</h5>
						</div>
					</div>
				) : (
					<ItemLoader style={{ marginTop: 20 }} />
				)}
				<div className={styles.chooseCoin}>
					<div
						className={styles.chooseCoinInfo}
						onClick={() =>
							navigate('/swap-coins', { state: { to: '/send-amount' } })
						}
					>
						<img src={chooseCoinOne.image.thumb} alt={chooseCoinOne.symbol} />
						<p className={styles.chooseTitle}>
							{chooseCoinOne?.name || chooseCoinOne?.symbol}
						</p>
						<svg
							width='16'
							height='16'
							viewBox='0 0 16 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path d='M12.6665 6L7.99984 10L3.33317 6' stroke='black' />
						</svg>
					</div>
					<span className={styles.chooseCoinPrice}>
						$ {fixNum(chooseCoinOne.market_data.balance_crypto)}
					</span>
				</div>
			</div>

			<div className='page-inner page-align-bottom'>
				<Button
					onClick={setValue}
					variant={disabledBtn ? 'disabled' : 'default'}
				>
					Continue
				</Button>
			</div>
		</div>
	);
};
