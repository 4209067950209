import React from 'react';
import styles from './welcome.module.css';
import { useNavigate } from 'react-router-dom';
import Svg from '../../svgs/Svg';
import logo from '../../assets/images/logo.svg';
import classNames from 'classnames';
import icon1 from '../../assets/images/start-icon.png';
import icon2 from '../../assets/images/start-icon2.png';

export const Start = () => {
	const navigate = useNavigate();

	return (
		<div className={classNames('page', styles.bg)}>
			<div className='page-inner' style={{ paddingTop: 70 }}>
				<div className={styles.logo}>
					<img style={{ marginBottom: 12 }} src={logo} alt='Martian Wallet' />
					<Svg type='logo' />
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<div className={styles.title} style={{ marginBottom: 15 }}>
					Let's get you started!
				</div>
				<div className={styles.wrapBtns} style={{ marginBottom: 25 }}>
					<button
						className={styles.buttonBig}
						onClick={() => navigate('/generate')}
					>
						<img src={icon1} alt='wallet' />
						<div className={styles.buttonInfo}>
							<span className={styles.buttonTitle}>Get a new wallet</span>
							<p className={styles.buttonPar}>
								I am new to crypto, or I want a shiny new wallet!
							</p>
						</div>
						<span className={styles.arrow}>
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path d='M9 5L15 12L9 19' stroke='black' strokeWidth='2' />
							</svg>
						</span>
					</button>
					<button
						className={styles.buttonBig}
						onClick={() => navigate('/import')}
					>
						<img src={icon2} alt='wallet' />
						<div className={styles.buttonInfo}>
							<span className={styles.buttonTitle}>
								Import an existing wallet
							</span>
							<p className={styles.buttonPar}>
								I already have a wallet and I want to
								<br />
								access my cryptos in Martian Wallet
							</p>
						</div>
						<span className={styles.arrow}>
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path d='M9 5L15 12L9 19' stroke='black' strokeWidth='2' />
							</svg>
						</span>
					</button>
				</div>
				<p className={styles.footerPar}>
					By proceeding, you agree to <br /> Martian Wallet{' '}
					<a
						href='https://robinhood.com/us/en/support/articles/privacy-policy/'
						target='_blank'
					>
						Terms of Use
					</a>
				</p>
			</div>
		</div>
	);
};
