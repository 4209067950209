import React, { useState } from 'react';
import Svg from '../../svgs/Svg';
import styles from './Tags.module.css';
import classNames from 'classnames/bind';

export const Tags = ({ content, setPhrase, copy, styleWrap }) => {
	const textRef = React.useRef(null);
	const [inputValue, setInputValue] = useState('');

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};
	React.useEffect(() => {
		if (inputValue !== '') {
			setPhrase(inputValue);
		}
	}, [inputValue]);

	async function pasteWords() {
		if (window.isSecureContext) {
			let pasteText = textRef?.current;
			pasteText.value = '';
			pasteText.focus();
			navigator.clipboard
				.readText()
				.then((text) => {
					setInputValue(text);
				})
				.catch((err) =>
					console.log('Failed to read clipboard contents: ', err)
				);
		}
	}

	const onCopy = async () => {
		try {
			await navigator.clipboard.writeText(content.join(' '));
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err);
		}
	};

	return (
		<>
			<div
				className={classNames(
					styles.wrapper,
					content ? styles.not_editable : ''
				)}
				style={{ marginTop: '30px', ...styleWrap }}
			>
				<input
					ref={textRef}
					style={{ position: 'absolute', left: -1000, opacity: 0 }}
				/>
				{!content ? (
					<div className={styles.inner}>
						<textarea
							type='text'
							placeholder='Recovery phrase/private key'
							value={inputValue}
							onChange={handleInputChange}
							className={styles.input}
						></textarea>
					</div>
				) : (
					<div className={styles.inner}>
						{content.map((item, index) => (
							<span key={index} className={styles.item}>
								{item}
							</span>
						))}
					</div>
				)}
				{copy ? (
					<button className={styles.copy} onClick={onCopy}>
						<span style={{ marginRight: 10 }}>
							<Svg type='copy' />
						</span>
					</button>
				) : (
					<button className={styles.copy} onClick={pasteWords}>
						<span style={{ marginRight: 10 }}>
							<Svg type='copy' />
						</span>
					</button>
				)}
			</div>
			<p className={styles.par}>
				Each word should be separated by a single space. <br /> No numbers,
				special characters or line breaks.
			</p>
		</>
	);
};
