import React from 'react';
import styles from './send.module.css';
import { Button, Header, Keyboard, Menu, PriceInput } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setChooseCoinOne } from '../../redux/slices/TransactionsSlice';
import { ItemLoader } from './../../components/Loaders/ItemLoader';
import Svg from '../../svgs/Svg';

export const Buy = () => {
	const dispatch = useDispatch();
	const { state } = useLocation();
	const { chooseCoinOne } = useSelector((state) => state.transaction);
	const [amountBuy, setAmountBuy] = React.useState('');

	React.useEffect(() => {
		if (state !== null) {
			if (state.from == 'swapOne') {
				dispatch(setChooseCoinOne(state.item));
			}
		}
	}, [state]);

	const onInputValue = (e) => {
		setAmountBuy(e.target.value.replace(/[^\d.]/g, ''));
	};

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>
			<div
				className='page-inner'
				style={{ display: 'flex', flexDirection: 'column' }}
			>
				<div className='page-header'>
					<div className='title' style={{ textAlign: 'center', width: '100%' }}>
						Add Cash
					</div>
				</div>

				{chooseCoinOne !== null ? (
					<div className={styles.wrapCoin} style={{ marginBottom: 40 }}>
						<div className={styles.logoBuy}>
							<Svg type='logo-icon' fill='var(--white)' />
							<img src={chooseCoinOne.image.thumb} />
						</div>
						<div style={{ marginLeft: 20, width: '70%', marginTop: -5 }}>
							<span className={styles.coinPrice}>
								$
								<input
									value={amountBuy}
									onChange={onInputValue}
									placeholder='0.00'
								/>
							</span>
							<div>
								<h5 className={styles.coinTitle}>{chooseCoinOne.name}</h5>
							</div>
						</div>
					</div>
				) : (
					<ItemLoader style={{ marginTop: 20, marginBottom: 40 }} />
				)}
			</div>
			<div className='page-inner page-align-bottom'>
				<Button
					variant={amountBuy !== '' && amountBuy > 0 ? 'default' : 'disabled'}
					onClick={() => window.open('https://www.moonpay.com/buy')}
				>
					Buy
				</Button>
			</div>
		</div>
	);
};
