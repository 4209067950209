import React from 'react';
import styles from './send.module.css';
import { Button, Menu, TextRowsBlock, Popup } from '../../components';
import { Header } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { resetTransaction } from '../../redux/slices/TransactionsSlice';
import { useNavigate } from 'react-router-dom';
import transactionsSend from '../Func.wallet/transaction';
import { setDataWallet } from '../../redux/slices/WalletSlice';
import CryptoJS from 'crypto-js';
import fixNum from '../Func.wallet/fixNum';
import Svg from '../../svgs/Svg';
const kitkat = 'Qsx@ah&OR82WX9T6gCt';

const texts = [
	{
		left: 'Transaction Fee',
		right: '0.34 USD',
	},
	{
		left: 'Reception time',
		right: '⚡ Instant (0 to 30 minutes)',
	},
];
const texts2 = [
	{
		left: 'Transaction Fee',
		right: '0.14 USD',
	},
	{
		left: 'Reception time',
		right: '🌱 2 hours in average',
	},
];
export const SendPreview = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { dataUser, currentWallet } = useSelector((state) => state.storage);
	const { chooseCoinOne, addressTo, amountSend } = useSelector(
		(state) => state.transaction
	);
	const { walletAddress } = useSelector((state) => state.wallet);
	const [textRows, setTextRows] = React.useState(texts);
	const [disabledBtn, setDisabledBtn] = React.useState(true);
	const [showText, setShowText] = React.useState(true);
	const [checkEther, setCheckEther] = React.useState(false);
	const [hash, setHash] = React.useState('');
	const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
	const [openModalGas, setOpenModalGas] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [currentAccount, setCurrentAccount] = React.useState(null);

	React.useEffect(() => {
		setCurrentAccount(dataUser.find((item) => item.name == currentWallet));
	}, [dataUser, currentWallet]);

	const buttonActions = [
		{
			title: '20%',
		},
		{
			title: '50%',
		},
		{
			title: '75%',
		},
		{
			title: '100%',
		},
	];

	const goHome = () => {
		dispatch(setDataWallet(null));
		navigate('/home');
		dispatch(resetTransaction());
	};

	React.useEffect(() => {
		if (chooseCoinOne != null && chooseCoinOne.symbol.toLowerCase() == 'eth') {
			setCheckEther(true);
		} else {
			setCheckEther(false);
		}
	}, [chooseCoinOne]);

	function onOpenModalSuccess(value) {
		setOpenModalSuccess(value);
		setLoading(false);
	}
	function onOpenModalGas(value) {
		setOpenModalGas(value);
		setLoading(false);
	}
	React.useEffect(() => {
		console.log(typeof amountSend);
	}, [amountSend]);
	const onSentToken = () => {
		if (!loading) {
			if (
				amountSend != '' &&
				amountSend > 0 &&
				addressTo != '' &&
				chooseCoinOne != null
			) {
				setLoading(true);
				transactionsSend(
					walletAddress,
					addressTo,
					chooseCoinOne.contract_address,
					String(amountSend),
					checkEther,
					setHash,
					onOpenModalSuccess,
					onOpenModalGas,
					CryptoJS.AES.decrypt(currentAccount.privateKey, kitkat).toString(
						CryptoJS.enc.Utf8
					)
				);
			}
		}
	};

	React.useEffect(() => {
		if (amountSend !== '' && amountSend > 0 && addressTo !== '') {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [amountSend, addressTo]);

	React.useEffect(() => {
		if (showText) {
			setTextRows(texts);
		} else {
			setTextRows(texts2);
		}
	}, [showText]);

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Preview Details' />
				<div className={styles.wrapCoin} style={{ marginBottom: 34 }}>
					<div className={styles.logo}>
						<Svg type='logo-icon' fill='var(--white)' />
					</div>
					<div
						style={{ marginTop: -9, maxWidth: '85%', pointerEvents: 'none' }}
					>
						<span className={styles.coinPrice}>
							<input type='text' value={amountSend} />
						</span>
						<h5 className={styles.coinTitle}>
							<span>
								≈ $
								{fixNum(chooseCoinOne.market_data.current_price * amountSend)}
							</span>
						</h5>
					</div>
				</div>
				<div className={styles.wrapAddress} style={{ pointerEvents: 'none' }}>
					<p className={styles.par}>Send to</p>
					<input
						placeholder='Paste address'
						className={styles.inputAddress}
						type='text'
						value={addressTo.slice(0, 30) + '...' + addressTo.slice(-7)}
					/>
				</div>
				<TextRowsBlock style={{ marginTop: 30 }} content={textRows} />
				<div className={styles.footer}>
					Martian Wallet cannot recover any lost funds.
				</div>
			</div>

			<div className='page-inner page-align-bottom'>
				<Button
					variant={disabledBtn ? 'disabled' : 'default'}
					onClick={onSentToken}
				>
					Confirm transaction
				</Button>
			</div>
			<Popup
				isOpen={openModalGas}
				title='Ooops...'
				label='Attention modal'
				text='Not enough funds.'
			>
				<Button variant='default' onClick={() => setOpenModalGas(false)}>
					Ok
				</Button>
			</Popup>
			<Popup
				isOpen={openModalSuccess}
				title='Success'
				label='Success modal'
				text='Your transaction has been sent.'
			>
				<Button variant='default' onClick={goHome}>
					Ok
				</Button>
			</Popup>
		</div>
	);
};
