import React from 'react';
import Svg from '../../svgs/Svg';
import styles from './Menu.module.css';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setMenuChoose } from '../../redux/slices/SettingsSlice';

export const Menu = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { menuChoose } = useSelector((state) => state.settings);

	const handleClick = (index) => {
		dispatch(setMenuChoose(index));
		switch (index) {
			case 1:
				navigate('/swap-coins', { state: { to: '/buy' } });
				break;
			case 2:
				navigate('/settings-account');
				break;
			default:
				navigate('/home');
		}
	};

	const menuLinks = [
		{
			icon: 'menu-item-1',
		},
		{
			icon: 'menu-item-2',
		},
		{
			icon: 'menu-item-4',
		},
	];

	return (
		<nav className={styles.row}>
			{menuLinks.map((item, index) => (
				<div
					className={classNames(styles.item)}
					onClick={() => handleClick(index)}
					key={index}
				>
					<Svg type={item.icon} fill={menuChoose === index && 'var(--black)'} />
				</div>
			))}
		</nav>
	);
};
