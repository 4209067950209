import React from 'react';
import styles from './welcome.module.css';
import { Button } from '../../components';
import { Link } from 'react-router-dom';
import Svg from '../../svgs/Svg';
import classNames from 'classnames';

export const Step2 = () => {
	return (
		<div className='page'>
			<Link to='/start' className={styles.skipLink}>
				Skip
			</Link>
			<div className={classNames('page-inner', styles.step2)}>
				<div className={styles.logo} style={{ height: '90vh' }}>
					<Svg
						style={{ height: 160, width: 160, marginTop: 160 }}
						type='logo-icon'
						className={styles.smallLogo}
					/>
					<div className={styles.title}>
						<Svg type='logo' />
						<div style={{ marginTop: 50 }}>
							A non-custodial
							<br />& secure wallet for
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
