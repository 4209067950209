import React from 'react';
import styles from './home.module.css';
import {
	Menu,
	MenuActions,
	SwipeToggle,
	Box,
	Popup,
	Button,
} from '../../components';
import Svg from '../../svgs/Svg';
import classNames from 'classnames/bind';

import { useSelector } from 'react-redux';
import fixNum from '../Func.wallet/fixNum';
import { useNavigate } from 'react-router-dom';

export const Activity = () => {
	const navigate = useNavigate();
	const { dataWallet } = useSelector((state) => state.wallet);
	const [showModal, setShowModal] = React.useState(false);
	const menuItems = [
		{
			title: 'Copy Address',
			icon: 'copy',
		},
		{
			title: 'View Recovery Phrase',
		},
		{
			title: 'View Private Key',
		},
		{
			title: 'Lock',
			icon: 'lock',
			color: 'var(--red)',
		},
	];
	const [transactionList, setTransactionList] = React.useState(null);

	React.useEffect(() => {
		if (dataWallet !== null) {
			let filtered = dataWallet.transactions.filter(
				(item) =>
					item.attributes.status !== 'failed' &&
					item.attributes.transfers[0] &&
					item.attributes.transfers[1] === undefined
			);

			function getFormatedDate(dateStr) {
				var month = [
					'Jan',
					'Feb',
					'Mar',
					'Apr',
					'May',
					'Jun',
					'Jul',
					'Aug',
					'Sep',
					'Oct',
					'Nov',
					'Dec',
				];
				var date = new Date(dateStr);
				return (
					date.getDate() +
					' ' +
					month[date.getMonth()] +
					' ' +
					date.getFullYear()
				);
			}
			const result = {};
			filtered.forEach(function (item) {
				var d = getFormatedDate(item.attributes.mined_at);
				if (result[d]) {
					result[d].push(item);
				} else {
					result[d] = [item];
				}
			});
			setTransactionList(result);
		}
	}, [dataWallet]);

	const variants = {
		swap: styles.swap,
		receive: styles.receive,
		send: styles.send,
	};

	const onClear = () => {
		setShowModal(false);
		setTransactionList([]);
	};

	return (
		<div className='page'>
			{/* Меню наверху, чтобы влиять на стили page-inner */}
			<div className='page-menu'>
				<Menu />
			</div>
			<div className='page-inner'>
				<div className='page-header' style={{ marginBottom: 25 }}>
					<div
						style={{ cursor: 'pointer' }}
						className='page-header--item'
						onClick={() => navigate('/home')}
					>
						<Svg type='wallet' fill='var(--primary)' />
						Wallet
					</div>
					<div
						style={{ cursor: 'pointer' }}
						className='page-header--item'
						onClick={() => navigate('/activity')}
					>
						<Svg type='activity' fill='var(--primary)' />
						Activity
					</div>
					<MenuActions items={menuItems} />
				</div>
				<div
					style={{ height: '50%' }}
					className={classNames(
						styles.list,
						!transactionList !== null ? styles.list_empty : ''
					)}
				>
					{transactionList !== null ? (
						Object.entries(transactionList).map((_, i) => (
							<div className={classNames(styles.item)} key={i}>
								<span className={styles.itemDate}>
									{Object.getOwnPropertyNames(transactionList)[i]}
								</span>
								{transactionList[
									Object.getOwnPropertyNames(transactionList)[i]
								].map((item, i) => (
									<Box
										key={i}
										className={classNames(
											styles.itemTransaction,
											variants[
												item.attributes.operation_type == 'trade'
													? 'swap'
													: item.attributes.operation_type == 'send'
													? 'send'
													: 'receive'
											]
										)}
									>
										<Svg type={item.type} />
										<div className={styles.transactionInfo}>
											<div className={styles.transactionAction}>
												<img
													src={
														item.attributes?.transfers[0]?.fungible_info?.icon
															?.url
													}
													alt=''
												/>
												{item.attributes.operation_type == 'trade' ? (
													<Svg type='swap' />
												) : item.attributes.operation_type == 'send' ? (
													<Svg type='send' />
												) : (
													<Svg type='receive' />
												)}
											</div>
											<div className={styles.inner}>
												<span className={styles.item_title}>
													{item.attributes?.transfers[0]?.fungible_info?.name}
												</span>
												<span className={styles.item_subtitle}>
													From:
													{' ' + item.attributes.transfers[0].sender &&
														item.attributes.transfers[0].sender.slice(0, 5) +
															'...' +
															item.attributes.transfers[0].sender.slice(-5)}
												</span>
											</div>
										</div>
										<div className={styles.inner_right}>
											<span className={styles.item_title}>
												$
												{fixNum(
													item.attributes.transfers[0].value !== null
														? item.attributes.transfers[0].value
														: 0
												)}
											</span>
										</div>
									</Box>
								))}
							</div>
						))
					) : (
						<div className={styles.empty}>
							<span>
								Your activity will
								<br /> appear here!
							</span>
						</div>
					)}
				</div>
			</div>
			<Popup isOpen={showModal} setIsOpen={setShowModal} title='Clear history'>
				<div className='btns' style={{ marginTop: 20 }}>
					<Button
						style={{ marginBottom: 0 }}
						variant='default'
						onClick={onClear}
					>
						Clear
					</Button>
					<Button variant='outlined' onClick={() => setShowModal(false)}>
						Cancel
					</Button>
				</div>
			</Popup>
		</div>
	);
};
