import React from 'react';
import { Button, CopyButton, FormColumns } from '../../components';
import styles from './mnemonicWords.module.css';
import Svg from '../../svgs/Svg';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import { Header } from '../../components/Header/Header';
const kitkat = 'Qsx@ah&OR82WX9T6gCt';

export const MnemonicWords = () => {
	const { dataUser, currentWallet } = useSelector((state) => state.storage);
	const [currentWalletData, setCurrentWalletData] = React.useState(undefined);

	React.useEffect(() => {
		if (dataUser.length && currentWallet !== '') {
			setCurrentWalletData(
				dataUser.find((item) => item.name === currentWallet)
			);
		}
	}, [dataUser, currentWallet]);

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header title='Save your phrase' />
				{currentWalletData !== undefined ? (
					<>
						<div className={styles.row}>
							<div className={styles.alertInfo}>
								<span>Never share </span>
								<p>your recovery phrase with anyone.</p>
							</div>
							<FormColumns
								disabled
								inputsList={CryptoJS.AES.decrypt(
									currentWalletData.phrase,
									kitkat
								)
									.toString(CryptoJS.enc.Utf8)
									.split(' ')}
							/>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: 25,
								}}
							>
								<CopyButton
									phrase={CryptoJS.AES.decrypt(
										currentWalletData.phrase,
										kitkat
									).toString(CryptoJS.enc.Utf8)}
									text='Copy to clipboard'
								/>
							</div>
							<p className={styles.par}>
								Write this 12 words carefully, or save them to a <br /> password
								manager.
							</p>
						</div>
					</>
				) : (
					<></>
				)}
			</div>
			<div className='page-inner page-align-bottom'>
				<Button variant='default' to='/backup-finish'>
					Next
				</Button>
				<Button variant='inline' to={-1}>
					Cancel
				</Button>
			</div>
		</div>
	);
};
