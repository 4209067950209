import React from 'react'
import styles from './PriceInputGroup.module.css'
import Svg from '../../svgs/Svg'
import { PriceInput } from '../../components'
import { ItemLoader } from '../Loaders/ItemLoader'

export const PriceInputGroup = ({
	setValueOne,
	valueOne,
	valueTwo,
	chooseItemOne,
	chooseItemTwo,
	onSwap,
}) => {
	return (
		<div className={styles.block}>
			{chooseItemOne !== null ? (
				<PriceInput
					setValue={setValueOne}
					value={valueOne}
					from='swapOne'
					chooseItem={chooseItemOne}
					title='You sell'
					to='/swap'
				/>
			) : (
				<ItemLoader px={80}/>
			)}
			<div
				className={styles.action}
				onClick={onSwap}
				style={{ cursor: 'pointer' }}>
				<Svg type='arrow-top' />
			</div>
			{chooseItemTwo !== null ? (
				<PriceInput
					disabled
					value={valueTwo}
					from='swapTwo'
					chooseItem={chooseItemTwo}
					title='You Buy'
					to='/swap'
				/>
			) : (
				<ItemLoader px={80}/>
			)}
		</div>
	)
}
