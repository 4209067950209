import React from 'react';
import styles from './Transaction.module.css';
import { Box, Chart } from '../';
import fixNum from './../../pages/Func.wallet/fixNum';
import { useNavigate } from 'react-router-dom';
import Svg from '../../svgs/Svg';
import classNames from 'classnames';

export const Transaction = ({ data, info = false }) => {
	const navigate = useNavigate();

	return (
		<Box
			style={{ cursor: 'pointer', padding: 0 }}
			onClick={() => navigate('/assets-info', { state: data })}
		>
			<div className={classNames(styles.inner, { [styles.card]: info })}>
				{info ? (
					<div className={styles.logoItem}>
						<Svg type='logo-icon' fill='var(--white)' />
					</div>
				) : (
					<div className={styles.logo}>
						<img src={data.image.thumb} alt={data.title} />
					</div>
				)}
				<div className={styles.name}>
					{info ? (
						<span style={{ textTransform: 'capitalize' }}>Available</span>
					) : (
						<span>{data.name}</span>
					)}
					<div style={{ color: '#9AA2B1' }}>
						{fixNum(data.market_data.balance)}
						{' ' + data.symbol}
					</div>
				</div>
				{/* <div className={styles.chart}>
					<Chart data={data.chart} color={data.chartColor} />
				</div> */}
				<div className={styles.info}>
					<span>${fixNum(data.market_data.balance_crypto)}</span>
				</div>
			</div>
		</Box>
	);
};
