import React from 'react';
import cn from 'classnames';
import styles from './generateItem.module.css';
import Svg from '../../svgs/Svg';

export const GenerateItem = ({
	first = false,
	loadWallet = true,
	delay = 10,
	title,
	icon,
}) => {
	const [showID, setShowID] = React.useState(null);
	const [show, setShow] = React.useState(false);
	const [load, setLoad] = React.useState(true);
	const [loadID, setLoadID] = React.useState(null);

	React.useEffect(() => {
		if (!loadWallet) {
			setShowID(
				setTimeout(() => {
					setShow(true);
				}, delay)
			);
		}

		return () => clearTimeout(showID);
	}, [loadWallet]);

	React.useEffect(() => {
		if (!loadWallet && !first) {
			setLoadID(
				setTimeout(() => {
					setLoad(false);
				}, delay + 3000)
			);
		} else if (!loadWallet && first) {
			setLoadID(
				setTimeout(() => {
					setLoad(false);
				}, 1000)
			);
		}
		return () => clearTimeout(loadID);
	}, [loadWallet]);
	return (
		<div
			className={cn(styles.item, {
				[styles.showItem]: show || first,
			})}
		>
			<div className={styles.bgLogo}>
				<Svg className={styles.logo} fill='var(--white)' type='logo-icon' />
				<Svg className={styles.logo} fill='var(--white)' type='logo-icon' />
			</div>
			<div>
				{!load ? (
					<Svg type={icon} />
				) : (
					<div className={styles.logImg}>
						<img
							style={{ width: 20, height: 7, marginBottom: 0 }}
							src='https://media.tenor.com/NqKNFHSmbssAAAAi/discord-loading-dots-discord-loading.gif'
							alt=''
						/>
					</div>
				)}
			</div>
			<p className={styles.par}>{title}</p>
		</div>
	);
};
