import React from 'react';
import Modal from 'react-modal';
import Svg from '../../../svgs/Svg';
import styles from './Popup.module.css';
import classNames from 'classnames/bind';
import logo from '../../../assets/images/logo.svg';

export const Popup = ({
	label,
	type,
	title,
	children,
	text,
	titleTextStyled,
	setIsOpen,
	isOpen,
	styleTitle,
}) => {
	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	const titleClasses = classNames(
		styles.title,
		styles.header_title,
		titleTextStyled ? styles.title_color : ''
	);

	return (
		<div className={styles.wrapper}>
			<Modal
				isOpen={isOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				className={styles.modal}
				overlayClassName={styles.overlay}
			>
				{type === 'warning' ? (
					<div className={styles.header}>
						<Svg type='logo-icon' className={styles.logo} fill='var(--red)' />
						<div className={styles.header_title}>{title}</div>
					</div>
				) : (
					<>
						<img className={styles.logo} src={logo} alt='logo' />
						<div className={titleClasses} style={styleTitle}>
							{title}
						</div>
					</>
				)}
				{text && <p className={styles.text}>{text}</p>}
				{children}
			</Modal>
		</div>
	);
};
