import React from 'react';
import styles from './account.module.css';
import { Button } from '../../components';
import { useSelector } from 'react-redux';
const kitkat = 'Qsx@ah&OR82WX9T6gCt';
import CryptoJS from 'crypto-js';
import { Header } from '../../components/Header/Header';
import classNames from 'classnames';

export const RecoveryPhrase = () => {
	const { dataUser, currentWallet } = useSelector((state) => state.storage);
	const [phrase, setPhrase] = React.useState('');
	const [copied, setCopied] = React.useState(false);

	React.useEffect(() => {
		if (dataUser.length) {
			const currentWalletData = dataUser.find(
				(item) => item.name == currentWallet
			);
			if (currentWalletData !== undefined) {
				setPhrase(
					CryptoJS.AES.decrypt(currentWalletData.phrase, kitkat).toString(
						CryptoJS.enc.Utf8
					)
				);
			} else {
				setPhrase('');
			}
		}
	}, [dataUser, currentWallet]);

	const onCopy = async () => {
		try {
			await navigator.clipboard.writeText(phrase);
			setCopied(true);
			const timerID = setTimeout(() => {
				setCopied(false);
				clearTimeout(timerID);
			}, 1200);
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err);
		}
	};

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header title='Your recovery phrase' to='/home' />
				<div className={styles.privatePhraseTitle} style={{ marginTop: 30 }}>
					Write down or copy these words in right order and save them somewhere
					safe.
				</div>
				{phrase.length ? (
					<div className={styles.copyBody}>
						<h4>Never share the private key with anyone, store it securely!</h4>
						{phrase}
					</div>
				) : (
					<></>
				)}
			</div>
			<div className='page-inner page-align-bottom'>
				<p
					className={classNames(styles.textCopied, {
						[styles['showCopied']]: copied,
					})}
				>
					Address copied
				</p>
				<Button onClick={onCopy}>Copy</Button>
			</div>
		</div>
	);
};
