import React from 'react';
import styles from './settings.module.css';
import { ItemBlock, Toggle, Header } from '../../components';

export const SettingsNotifications = () => {
	const list = [
		{
			title: 'Activate notifications',
		},
		{
			title: 'Transactions',
		},
		{
			title: 'WalletConnect',
		},
	];

	const handleToggle = (isChecked) => {
		console.log(isChecked);
	};

	return (
		<>
			<div className={styles.list}>
				{list.map((item, index) => (
					<ItemBlock content={item} key={index}>
						<Toggle id={`notification` + index} onToggle={handleToggle} />
					</ItemBlock>
				))}
			</div>
		</>
	);
};
