import React from 'react';
import { Button, Menu, Popup, PopupFull } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import fixNum from '../Func.wallet/fixNum';
import { resetWallet } from '../../redux/slices/StorageSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { setDeleteName } from '../../redux/slices/WalletSlice';
import styles from './account.module.css';
import Svg from '../../svgs/Svg';
import cn from 'classnames';
import { Header } from '../../components/Header/Header';

export const AccountWallets = () => {
	const { state } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { dataUser, currentWallet, usePin, backup } = useSelector(
		(state) => state.storage
	);
	const { walletAddress, portfolioBalanceUsd, deleteName } = useSelector(
		(state) => state.wallet
	);
	const [openDelete, setOpenDelete] = React.useState(false);
	const [openDeleteWarning, setOpenDeleteWarning] = React.useState(false);
	const [openDeleteBackup, setOpenDeleteBackup] = React.useState(false);
	const [openDeleteBackupWarning, setOpenDeleteBackupWarning] =
		React.useState(false);

	const onDeleteWallet = (backup, name) => {
		dispatch(setDeleteName(name));
		if (backup) {
			setOpenDelete(true);
		} else {
			setOpenDeleteBackup(true);
		}
	};

	React.useEffect(() => {
		if (state !== null) {
			if (backup) {
				setOpenDeleteWarning(true);
			} else {
				setOpenDeleteBackupWarning(true);
			}
		}
	}, [state]);

	const onDeleteWalletWarning = (modal) => {
		if (modal) {
			setOpenDelete(false);
			if (usePin) {
				navigate('/login-pin', { state: { to: '/wallets' } });
			} else {
				setOpenDeleteWarning(true);
			}
		} else {
			setOpenDeleteBackup(false);
			if (usePin) {
				navigate('/login-pin', { state: { to: '/wallets' } });
			} else {
				setOpenDeleteBackupWarning(true);
			}
		}
	};

	const onDeleteAccount = () => {
		navigate('/');
		dispatch(resetWallet());
	};

	const goKeyOrPhrase = (to) => {
		if (usePin) {
			navigate('/login-pin', { state: { to: to } });
		} else {
			navigate(to);
		}
	};

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Wallets' />
				<div className={styles.cardInfo}>
					<div className={styles.cardInfoTop}>
						<div className={styles.logo}>
							<Svg type='logo-icon' fill='var(--white)' />
						</div>
						<div>
							<h4>My Balance</h4>
							<p>Wallet name </p>
						</div>
					</div>
					<span>$ {fixNum(portfolioBalanceUsd)}</span>
					<p>{walletAddress}</p>
				</div>
				<button
					className={styles.buttonBlack}
					onClick={() => goKeyOrPhrase('/copy-phrase')}
				>
					<div>
						<Svg type='stats' />
						<span>View Recovery Phrase</span>
					</div>
					<Svg type='arrow-right' />
				</button>
				<button
					className={styles.buttonBlack}
					onClick={() => goKeyOrPhrase('/copy-key')}
				>
					<div>
						<Svg type='key' />
						<span>View Private Key</span>
					</div>
					<Svg type='arrow-right' />
				</button>
				<button
					onClick={() => onDeleteWallet(currentWallet)}
					className={cn(styles.buttonBlack)}
				>
					<div>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M20 1.33333H16.25L15.1786 0H9.82143L8.75 1.33333H5V4H20M6.07143 21.3333C6.07143 22.0406 6.29719 22.7189 6.69906 23.219C7.10092 23.719 7.64596 24 8.21429 24H16.7857C17.354 24 17.8991 23.719 18.3009 23.219C18.7028 22.7189 18.9286 22.0406 18.9286 21.3333V5.33333H6.07143V21.3333Z'
								fill='black'
							/>
						</svg>
						<span>Delete wallet</span>
					</div>
					<Svg type='arrow-right' />
				</button>
			</div>
			<Popup
				isOpen={openDeleteBackup}
				title='Delete wallet'
				text='To delete your wallet, you mast perform a manual backup.'
			>
				<Button variant='default' onClick={() => onDeleteWalletWarning(false)}>
					Okay
				</Button>
				<Button variant='inline' onClick={() => setOpenDeleteBackup(false)}>
					Cancel
				</Button>
			</Popup>
			<Popup
				isOpen={openDelete}
				title='Delete wallet'
				type='warning'
				text='Deleting this wallet will erase your current wallet.'
			>
				<div className='btns'>
					<Button variant='default' onClick={() => onDeleteWalletWarning(true)}>
						Delete
					</Button>
					<Button variant='outlined' onClick={() => setOpenDelete(false)}>
						Cancel
					</Button>
				</div>
			</Popup>
			{/* <Popup
				isOpen={openDeleteWarning}
				type='warning'
				title='Warning'
				titleTextStyled
				text='Are you sure you want to delete the wallet? Please, make sure you saved the phrase. Funds associated with the wallet will not be affected.'
			>
				<Button variant='default' onClick={onDeleteAccount}>
					Delete
				</Button>
				<Button variant='inline' onClick={() => setOpenDeleteWarning(false)}>
					Cancel
				</Button>
			</Popup> */}
			<Popup
				isOpen={openDeleteBackupWarning}
				type='warning'
				title='Warning'
				titleTextStyled
				text='Without the manual backup of your current wallet, you will lose access to its funds forever.'
			>
				<Button
					variant='default'
					colorText='var(--red)'
					onClick={onDeleteAccount}
				>
					Delete
				</Button>
				<Button
					variant='inline'
					onClick={() => setOpenDeleteBackupWarning(false)}
				>
					Cancel
				</Button>
			</Popup>
			<PopupFull
				setIsOpen={setOpenDeleteWarning}
				isOpen={openDeleteWarning}
				headerTitle='Warning'
				headerIcon='alert-triangle'
			>
				<p className={styles.subTitle}>
					Are you sure you want to delete the wallet?
				</p>
				<p className={styles.par}>
					Please, make sure you saved the <span>phrase</span>. <br />
					Funds associated with the wallet will not be affected.
				</p>
				<div className='btns' style={{ margin: '40px 5% 0' }}>
					<Button onClick={onDeleteAccount}>Delete</Button>
					<Button
						variant='outlined'
						onClick={() => setOpenDeleteWarning(false)}
					>
						Cancel
					</Button>
				</div>
			</PopupFull>
		</div>
	);
};
