import React from 'react';
import styles from './cardPrice.module.css';
import { Indicators } from '../Indicators/Indicators';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Svg from '../../svgs/Svg';
import fixNum from '../../pages/Func.wallet/fixNum';

export const CardPrice = ({ balance, change }) => {
	const navigate = useNavigate();
	const { walletAddress } = useSelector((state) => state.wallet);
	const [copied, setCopied] = React.useState(false);

	const copyThis = async () => {
		try {
			await navigator.clipboard.writeText(walletAddress);
			setCopied(true);
			const timerID = setTimeout(() => {
				setCopied(false);
				clearTimeout(timerID);
			}, 1200);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<div className={styles.card}>
			<div className={styles.balanceInfo}>
				<div className={styles.logo}>
					<Svg fill='var(--white)' type='logo-icon' />
				</div>
				<div>
					<p>My Balance</p>
					<span>
						{walletAddress !== ''
							? walletAddress.slice(0, 6) + '...' + walletAddress.slice(-4)
							: ''}
						<div className={styles.copyBtn} onClick={copyThis}>
							<Svg type='copy-inlined' fill={copied ? 'var(--black)' : null} />
						</div>
					</span>
				</div>
			</div>
			<div>
				<span className={styles.priceTitle}>${balance}</span>
				{change !== null ? (
					<span className={styles.changePar}>
						{change.percent > 0 ? '+' : ''}
						{fixNum(change.percent)}%<Svg type='change-top' />
					</span>
				) : (
					<></>
				)}
				<p className={styles.profitPar}>Today’s Profit</p>
			</div>
		</div>
	);
};
