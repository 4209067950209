import React from 'react';
import styles from './welcome.module.css';
import { Link } from 'react-router-dom';
import { Button } from '../../components';
import logo from '../../assets/images/lockCirlce.png';
import classNames from 'classnames';
import Svg from '../../svgs/Svg';

export const Step3 = () => {
	return (
		<div className={classNames('page', styles.step3)}>
			<Link to='/start' className={styles.skipLink}>
				Skip
			</Link>
			<div className='page-inner'>
				<div className={styles.logo} style={{ height: '90vh' }}>
					<img src={logo} className={styles.lockCircle} alt='Martian Wallet' />
					<div className={styles.title}>
						<Svg type='logo' />
						<div style={{ marginTop: 50 }}>
							Access the world
							<br />
							of cryto & DeFi
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
