import React from 'react';
import styles from './import.module.css';
import { ButtonLink, Header } from '../../components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import btnImg1 from '../../assets/images/start-icon.png';
import btnImg2 from '../../assets/images/start-icon2.png';

export const ImportStart = () => {
	const navigate = useNavigate();
	const { backup } = useSelector((state) => state.storage);

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header
					title={
						<>
							Import an existing wallet using <br /> a recovery phrase
						</>
					}
				/>
				<div className={styles.list}>
					<ButtonLink
						imgSource={btnImg1}
						onClick={() => navigate('/backup')}
						title='Create a backup for you current'
						subtitle='This step is required, even if your current wallet is empty, for security.'
					/>
					<ButtonLink
						imgSource={btnImg2}
						disabled={!backup}
						onClick={() => navigate('/import')}
						title='Import your other wallet using a recovery phrase'
					/>
				</div>
			</div>
		</div>
	);
};
