import React from 'react';
import styles from './backup.module.css';
import { Button, Checkbox, Menu } from '../../components';
import { Header } from '../../components/Header/Header';

export const BackupStart = () => {
	const [submitValue, setSubmitValue] = React.useState(false);
	return (
		<div className='page'>
			<div className='page-inner'>
				<Header title='Manual backup' />
				<div className={styles.box}>
					<h6>
						Martian Wallet will display the 12 words of your recovery phrase.
						This is your password and the only way to restore your wallet.
					</h6>
					<p>
						To backup your recovery phrase Either write it on a paper that you
						will store in a safe place;
					</p>
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<div className={styles.checkbox}>
					<Checkbox
						item={submitValue}
						activeItem={true}
						onChangeBoolean={setSubmitValue}
						type='checkbox'
						variant='agreement'
						id='backup'
						label='I understand that if I lose my recovery phrase, I will lose my funds.'
					/>
				</div>
				<Button
					variant={!submitValue ? 'disabled' : 'default'}
					to='/backup-mnemonic'
				>
					I understand
				</Button>
				<Button variant='inline' to={-1}>
					Cancel
				</Button>
			</div>
		</div>
	);
};
