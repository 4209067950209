import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './welcome.module.css';
import { Carousel } from 'react-responsive-carousel';
import { Button } from '../../components';
import { Link, useNavigate } from 'react-router-dom';
import { Step2 } from './step2';
import { Step3 } from './step3';
import Svg from '../../svgs/Svg';

export const Step1 = () => {
	const navigate = useNavigate();
	const [selectedIndex, setSelectedIndex] = React.useState(1);

	function handleChange(selectedIndex) {
		setSelectedIndex(selectedIndex);
	}

	const goNextSlide = (callback) => {
		callback();
		if (selectedIndex == 2) {
			navigate('/start');
		}
	};

	return (
		<div className='step'>
			<Carousel
				onChange={handleChange}
				showThumbs={false}
				emulateTouch
				showArrows={false}
				showStatus={false}
				swipeable={true}
				renderArrowNext={(clickHandler) => {
					return (
						<Button
							onClick={() => goNextSlide(clickHandler)}
							style={{ marginTop: 'auto', bottom: 85 }}
						>
							Next
						</Button>
					);
				}}
			>
				<div className='page page-step'>
					<Link to='/start' className={styles.skipLink}>
						Skip
					</Link>

					<div
						className='page-inner'
						style={{ overflowX: 'hidden', position: 'relative' }}
					>
						<div
							className={styles.logo}
							style={{ paddingTop: 170, height: '90vh' }}
						>
							<Svg className={styles.logoLeft} type='logo-icon' />
							<Svg type='logo' />
							<Svg className={styles.logoRight} type='logo-icon' />
							<div className={styles.title}>
								The crypto wallet <br /> for everyone
							</div>
						</div>
					</div>
				</div>
				<Step2 />
				<Step3 />
			</Carousel>
		</div>
	);
};
