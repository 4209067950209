import React from 'react';
import { Button, FormColumns, Popup } from '../../components';
import styles from './mnemonicWords.module.css';
import Svg from '../../svgs/Svg';
import { useSelector, useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
const kitkat = 'Qsx@ah&OR82WX9T6gCt';
import { useNavigate } from 'react-router-dom';
import { setBackup } from '../../redux/slices/StorageSlice';
import { Header } from '../../components/Header/Header';

export const MnemonicWordsFinish = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { dataUser, currentWallet } = useSelector((state) => state.storage);
	const [currentWalletData, setCurrentWalletData] = React.useState(undefined);
	const [numsWords, setNumsWords] = React.useState([]);
	const [values, setValues] = React.useState(['', '', '', '']);
	const [disabledBtn, setDisabledBtn] = React.useState(true);
	const [showValid, setShowValid] = React.useState(false);
	const [showSuccess, setShowSuccess] = React.useState(false);

	React.useEffect(() => {
		if (dataUser.length && currentWallet !== '') {
			setCurrentWalletData(
				dataUser.find((item) => item.name === currentWallet)
			);
		}
	}, [dataUser, currentWallet]);

	React.useEffect(() => {
		const fullWords = values.filter((item) => item !== '');

		if (fullWords.length == 4) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [values]);
	const onChangeInput = (value, ind) => {
		setValues((state) => state.map((item, i) => (i == ind ? value : item)));
	};

	const submitBackup = () => {
		const phrase = CryptoJS.AES.decrypt(currentWalletData.phrase, kitkat)
			.toString(CryptoJS.enc.Utf8)
			.split(' ');
		let w1 = phrase[numsWords[0] - 1] == values[0].toLowerCase();
		let w2 = phrase[numsWords[1] - 1] == values[1].toLowerCase();
		let w3 = phrase[numsWords[2] - 1] == values[2].toLowerCase();
		let w4 = phrase[numsWords[3] - 1] == values[3].toLowerCase();
		if (w1 && w2 && w3 && w4) {
			dispatch(setBackup(true));
			setShowSuccess(true);
		} else {
			setShowValid(true);
		}
	};

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header title='Finish backup' />

				<div
					className='subtitle'
					style={{ marginTop: 25, textAlign: 'center', color: 'var(--black)' }}
				>
					Check you have made a correct backup by entering the corresponding
					words from your recovery phrase below.
				</div>

				<div className={styles.row}>
					<FormColumns
						check
						onChangeInput={onChangeInput}
						values={values}
						setNums={setNumsWords}
						randomValues
						inputsList={['Word here', 'Word here', 'Word here', 'Word here']}
					/>

					{/* {currentWalletData !== undefined ? (
						<FormColumns
							disabled
							inputsList={CryptoJS.AES.decrypt(currentWalletData.phrase, kitkat)
								.toString(CryptoJS.enc.Utf8)
								.split(' ')}
						/>
					) : (
						<></>
					)} */}
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<Button
					onClick={submitBackup}
					variant={disabledBtn ? 'disabled' : 'default'}
				>
					Done
				</Button>
			</div>
			<Popup
				isOpen={showValid}
				title={
					<>
						You entered
						<br />
						the wrong words
					</>
				}
				text='Please, go back and remember the phrase better.'
			>
				<Button variant='default' onClick={() => setShowValid(false)}>
					Okay
				</Button>
			</Popup>
			<Popup
				isOpen={showSuccess}
				title={<>Your backup was successful</>}
				text={`Don't give the phrase to anyone.`}
			>
				<Button variant='default' to='/settings-account'>
					Okay
				</Button>
			</Popup>
		</div>
	);
};
