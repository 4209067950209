import React from 'react';
import Modal from 'react-modal';
import Svg from '../../../svgs/Svg';
import styles from './popupFull.module.css';
import classNames from 'classnames/bind';
import logo from '../../../assets/images/logo.svg';

export const PopupFull = ({
	type,
	title,
	children,
	text,
	titleTextStyled,
	setIsOpen,
	isOpen,
	styleTitle,
	headerTitle = '',
	headerIcon = '',
	close = false,
}) => {
	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	const titleClasses = classNames(
		styles.title,
		styles.header_title,
		titleTextStyled ? styles.title_color : ''
	);

	return (
		<div className={styles.wrapper}>
			<Modal
				isOpen={isOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				className={styles.modal}
				overlayClassName={styles.overlay}
			>
				<div className={styles.header}>
					<h5>{headerTitle}</h5>
					{!close ? (
						<Svg type={headerIcon} />
					) : (
						<div style={{ cursor: 'pointer' }} onClick={closeModal}>
							<Svg type='close' />
						</div>
					)}
				</div>
				{children}
			</Modal>
		</div>
	);
};
