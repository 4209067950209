import React from 'react';
import styles from './account.module.css';
import { Menu, PopupFull } from '../../components';
import Svg from '../../svgs/Svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Settings } from '../settings/settings';
import { SettingsNotifications } from '../settings/settingsNotifications';
import { SettingsAbout } from '../settings/settingsAbout';
import { DAppsStart } from '../dApps/dAppsStart';

export const Account = () => {
	const [showSecurity, setShowSecurity] = React.useState(false);
	const [showNotifications, setShowNotifications] = React.useState(false);
	const [showAbout, setShowAbout] = React.useState(false);
	const [showConnect, setShowConnect] = React.useState(false);

	const navigate = useNavigate();
	const { backup } = useSelector((state) => state.storage);
	const list = [
		{
			title: 'Wallet',
			to: '/wallets',
			icon: 'wallet',
		},
		{
			title: 'Import existing wallet',
			to: '/import-start',
			icon: 'import',
		},
		{
			title: 'Backup',
			status: 'success',
			to: '/backup',
			icon: 'backup',
		},
		{
			title: 'Security',
			icon: 'lock',
			onClick: setShowSecurity,
		},
		{
			title: 'Notifications',
			icon: 'notification',
			onClick: setShowNotifications,
		},
		// {
		// 	title: 'Currency',
		// 	to: '/currency',
		// },
		// {
		// 	title: 'RPC Node ',
		// 	to: '/rpc',
		// 	icon: 'rpc'
		// },
		{
			title: 'Wallet Connect',
			icon: 'connect-wallet',
			onClick: setShowConnect,
		},
		{
			title: 'About',
			onClick: setShowAbout,
			icon: 'logo-icon',
		},
	];

	const onClickLink = (item) => {
		if (item.to) {
			navigate(item.to);
		} else if (item.onClick) {
			item.onClick(true);
		} else {
			console.log('coming soon!');
		}
	};

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className='page-header'>
					<div className='title'>Settings account</div>
				</div>

				<div className={styles.list}>
					{list.map((item, index) => (
						<div
							className={styles.item}
							key={index}
							onClick={() => onClickLink(item)}
						>
							<span
								style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
							>
								<Svg type={item.icon} />
								{item.title}
							</span>
							<div>
								{item.status &&
									(item.status === 'success' && backup ? (
										<Svg
											type='logo-icon'
											fill='var(--success)'
											style={{ marginRight: 10 }}
										/>
									) : item.status === 'success' && !backup ? (
										<Svg
											type='logo-icon'
											fill='var(--attention)'
											style={{ marginRight: 10 }}
										/>
									) : (
										''
									))}
								<Svg type='arrow-right' />
							</div>
						</div>
					))}
				</div>
			</div>
			<PopupFull
				close
				headerTitle={
					<>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<rect width='24' height='24' rx='12' fill='black' />
							<path
								d='M17.8333 8.33333H15.5V6.55556C15.5 5.61256 15.1313 4.70819 14.4749 4.0414C13.8185 3.3746 12.9283 3 12 3C11.0717 3 10.1815 3.3746 9.52513 4.0414C8.86875 4.70819 8.5 5.61256 8.5 6.55556V8.33333H6.16667C5.85725 8.33333 5.5605 8.4582 5.34171 8.68047C5.12292 8.90273 5 9.20419 5 9.51852V17.8148C5 18.1291 5.12292 18.4306 5.34171 18.6529C5.5605 18.8751 5.85725 19 6.16667 19H17.8333C18.1428 19 18.4395 18.8751 18.6583 18.6529C18.8771 18.4306 19 18.1291 19 17.8148V9.51852C19 9.20419 18.8771 8.90273 18.6583 8.68047C18.4395 8.4582 18.1428 8.33333 17.8333 8.33333ZM12.5833 14.1578V16.037C12.5833 16.1942 12.5219 16.3449 12.4125 16.4561C12.3031 16.5672 12.1547 16.6296 12 16.6296C11.8453 16.6296 11.6969 16.5672 11.5875 16.4561C11.4781 16.3449 11.4167 16.1942 11.4167 16.037V14.1578C11.0275 14.018 10.6994 13.7429 10.4905 13.3812C10.2817 13.0195 10.2054 12.5944 10.2752 12.181C10.345 11.7677 10.5564 11.3928 10.872 11.1225C11.1877 10.8521 11.5872 10.7039 12 10.7039C12.4128 10.7039 12.8123 10.8521 13.128 11.1225C13.4436 11.3928 13.655 11.7677 13.7248 12.181C13.7946 12.5944 13.7183 13.0195 13.5095 13.3812C13.3006 13.7429 12.9725 14.018 12.5833 14.1578ZM14.3333 8.33333H9.66667V6.55556C9.66667 5.92689 9.9125 5.32398 10.3501 4.87945C10.7877 4.43492 11.3812 4.18519 12 4.18519C12.6188 4.18519 13.2123 4.43492 13.6499 4.87945C14.0875 5.32398 14.3333 5.92689 14.3333 6.55556V8.33333Z'
								fill='white'
							/>
						</svg>
						Security
					</>
				}
				setIsOpen={setShowSecurity}
				isOpen={showSecurity}
			>
				<Settings />
			</PopupFull>
			<PopupFull
				close
				headerTitle={
					<>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<rect width='24' height='24' rx='12' fill='black' />
							<path
								d='M5.39973 17.0491H18.9268C19.7903 17.0491 20.3281 16.5962 20.3281 15.9138C20.3281 15.0531 19.5021 14.3112 18.7491 13.6024C18.1564 13.0454 18.0146 11.8737 17.9071 10.8065C17.7884 7.79541 16.8785 5.67447 14.6798 4.90844C14.3321 3.82818 13.4152 3.00195 12.1598 3.00195C10.9129 3.00195 9.98914 3.82818 9.64823 4.90844C7.44959 5.67447 6.53819 7.79541 6.42093 10.8065C6.31352 11.8737 6.17017 13.0454 5.579 13.6024C4.81907 14.3112 4 15.0531 4 15.9138C4 16.5962 4.52932 17.0491 5.39973 17.0491ZM12.1598 20.4171C13.658 20.4171 14.7512 19.3823 14.8599 18.1931H9.46829C9.57537 19.3823 10.6701 20.4171 12.1598 20.4171Z'
								fill='white'
							/>
						</svg>
						Notification
					</>
				}
				setIsOpen={setShowNotifications}
				isOpen={showNotifications}
			>
				<SettingsNotifications />
			</PopupFull>
			<PopupFull
				close
				headerTitle={
					<>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<rect width='24' height='24' rx='12' fill='black' />
							<path
								d='M5.39973 17.0491H18.9268C19.7903 17.0491 20.3281 16.5962 20.3281 15.9138C20.3281 15.0531 19.5021 14.3112 18.7491 13.6024C18.1564 13.0454 18.0146 11.8737 17.9071 10.8065C17.7884 7.79541 16.8785 5.67447 14.6798 4.90844C14.3321 3.82818 13.4152 3.00195 12.1598 3.00195C10.9129 3.00195 9.98914 3.82818 9.64823 4.90844C7.44959 5.67447 6.53819 7.79541 6.42093 10.8065C6.31352 11.8737 6.17017 13.0454 5.579 13.6024C4.81907 14.3112 4 15.0531 4 15.9138C4 16.5962 4.52932 17.0491 5.39973 17.0491ZM12.1598 20.4171C13.658 20.4171 14.7512 19.3823 14.8599 18.1931H9.46829C9.57537 19.3823 10.6701 20.4171 12.1598 20.4171Z'
								fill='white'
							/>
						</svg>
						About
					</>
				}
				setIsOpen={setShowAbout}
				isOpen={showAbout}
			>
				<SettingsAbout />
			</PopupFull>
			<PopupFull
				close
				headerTitle={
					<>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<rect width='24' height='24' rx='12' fill='black' />
							<path
								d='M5.39973 17.0491H18.9268C19.7903 17.0491 20.3281 16.5962 20.3281 15.9138C20.3281 15.0531 19.5021 14.3112 18.7491 13.6024C18.1564 13.0454 18.0146 11.8737 17.9071 10.8065C17.7884 7.79541 16.8785 5.67447 14.6798 4.90844C14.3321 3.82818 13.4152 3.00195 12.1598 3.00195C10.9129 3.00195 9.98914 3.82818 9.64823 4.90844C7.44959 5.67447 6.53819 7.79541 6.42093 10.8065C6.31352 11.8737 6.17017 13.0454 5.579 13.6024C4.81907 14.3112 4 15.0531 4 15.9138C4 16.5962 4.52932 17.0491 5.39973 17.0491ZM12.1598 20.4171C13.658 20.4171 14.7512 19.3823 14.8599 18.1931H9.46829C9.57537 19.3823 10.6701 20.4171 12.1598 20.4171Z'
								fill='white'
							/>
						</svg>
						About
					</>
				}
				setIsOpen={setShowConnect}
				isOpen={showConnect}
			>
				<DAppsStart />
			</PopupFull>
		</div>
	);
};
